<template>
    <main>
        <!-- Credit Card Info Modal -->
        <ModalCreditCardInfo
            ref="creditCardModal"
            v-show="showCreditCardModal"
            @close="hideCreditCardModal()"
        />

        <!-- PO Detail Section -->
        <v-card class="po-detail-section">
            <!-- PO Number Heading Row -->
            <v-row>
                <v-col cols="12" sm="4" class="col-padding-dense">
                    <v-row>
                        <v-col cols="12" sm="4">
                            <h2>PO#</h2>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <h2>{{ currentPurchaseOrder.number }}</h2>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <!-- PO Details Row -->
            <v-row class="row-po-details">
                <!--
                    COLUMN 1
                -->
                <v-col cols="12" lg="4" class="col-padding-dense">
                    <!-- Owner -->
                    <v-row>
                        <v-col cols="12" sm="4"><h3>Owner</h3></v-col>
                        <v-col cols="12" sm="8">{{ locationName }}</v-col>
                    </v-row>
                    <!-- Create Date -->
                    <v-row>
                        <v-col cols="12" sm="4"><h3>Create Date</h3></v-col>
                        <v-col cols="12" sm="8">
                            {{ currentPurchaseOrder.createDate }}
                        </v-col>
                    </v-row>
                    <!-- Transmit Date -->
                    <v-row>
                        <v-col cols="12" sm="4" alignSelf="center"><h3>Transmit Date</h3></v-col>
                        <v-col cols="12" sm="8">
                            <InputDatePicker
                                label="Transmit Date"
                                :date.sync="transmitDate"
                                outlined
                                :disabled="isPurchaseOrderStatusComplete"
                            />
                        </v-col>
                    </v-row>
                    <!-- Expected Date -->
                    <v-row>
                        <v-col cols="12" sm="4" alignSelf="center"><h3>Expected Date</h3></v-col>
                        <v-col cols="12" sm="8">
                            <InputDatePicker
                                label="Expected Date"
                                :date.sync="expectedReceiveDate"
                                outlined
                                :disabled="isPurchaseOrderStatusComplete"
                                :min-date="new Date().toISOString().substring(0, 10)"
                            />
                        </v-col>
                    </v-row>
                    <!-- History -->
                    <v-row>
                        <v-col cols="12">
                            <h3 class="mb-3">History</h3>
                            <v-textarea
                                v-model="description"
                                :disabled="isPurchaseOrderStatusComplete"
                                rows="8"
                                outlined
                                noResize
                            />
                        </v-col>
                    </v-row>
                </v-col>
                <!--
                    COLUMN 2
                -->
                <v-col cols="12" lg="4" class="col-padding-dense">
                    <!-- Tracking Numbers -->
                    <v-row>
                        <v-col cols="12" sm="4" alignSelf="center"><h3>Tracking Numbers</h3></v-col>
                        <v-col cols="12" sm="8">
                        <v-textarea
                            v-model="trackingNumbers"
                            class="po-tracking-numbers"
                            :disabled="isPurchaseOrderStatusComplete"
                            rows="2"
                            outlined
                            dense
                            hideDetails
                        />
                        </v-col>
                    </v-row>
                    <!-- Confirmation ID -->
                    <v-row>
                        <v-col cols="12" sm="4" alignSelf="center"><h3>Confirmation</h3></v-col>
                        <v-col cols="12" sm="8">
                            <v-text-field
                                v-model="confirmationId"
                                :disabled="isPurchaseOrderStatusComplete"
                                outlined
                                dense
                                hideDetails
                            />
                        </v-col>
                    </v-row>
                    <!-- Supplier -->
                    <v-row class="row-supplier">
                        <v-col cols="12" sm="4" alignSelf="center"><h3>Supplier</h3></v-col>
                        <v-col cols="12" sm="8">
                            <DropdownPrimarySupplier
                                v-model="supplier"
                                :disabled="isPurchaseOrderStatusComplete"
                                hideDetails
                            />
                        </v-col>
                    </v-row>
                    <!-- Supplier Notes -->
                    <v-row>
                        <v-col cols="12">
                            <h3 class="mb-3">Supplier Notes</h3>
                            <div id="po-supplier-notes" v-html="supplierNote"></div>
                        </v-col>
                    </v-row>
                </v-col>
                <!--
                    COLUMN 3
                -->
                <v-col cols="12" lg="4" class="col-padding-dense">
                    <!-- Total Units Ordered -->
                    <v-row>
                        <v-col cols="7">
                            <h3>Total Units Ordered:</h3>
                        </v-col>
                        <v-col cols="5">
                            {{ numberOfUnitsOrdered }}
                        </v-col>
                    </v-row>
                    <!-- Number of EANs -->
                    <v-row>
                        <v-col cols="7">
                            <h3>Number of EANs:</h3>
                        </v-col>
                        <v-col cols="5">
                            {{ numberOfISBNsOrdered }}
                        </v-col>
                    </v-row>
                    <!-- Credit Card Payment -->
                    <v-row>
                        <v-col cols="7">
                            <h3>Credit Card Payment</h3>
                        </v-col>
                        <v-col cols="5">
                            <input
                                v-model="useCreditCard"
                                style="margin-right: 0.25rem;"
                                type="checkbox"
                                :disabled="isPurchaseOrderStatusComplete"
                            >
                            <span style="display: inline-block;">
                                <v-tooltip
                                    v-if="useCreditCard"
                                    color="black"
                                    left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            mdi-information
                                        </v-icon>
                                    </template>
                                    <p class="pt-4">Notes: {{ creditCardNotes || 'None set'}}</p>
                                    <p>Last Four: {{ creditCardLastFour || 'None set'}}</p>
                                </v-tooltip>
                            </span>
                        </v-col>
                    </v-row>
                    <!-- Payment Terms -->
                    <v-row>
                        <v-col cols="12" sm="6">
                            <h3>Payment Terms</h3>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <p >30 Days / 0.0000 %</p>
                        </v-col>
                    </v-row>
                    <!-- Cost Card -->
                    <v-row class="row-cost-card">
                        <v-col cols="12">
                            <v-card outlined class="row-cost-card__card">
                                <v-card-text>
                                    <!-- Subtotal -->
                                    <v-row>
                                        <v-col cols="6" sm="4">
                                            <h3>Subtotal</h3>
                                        </v-col>
                                        <v-col cols="6" sm="8" class="text-right">
                                            <span class="body-1">
                                                {{ currentPurchaseOrderSubtotal }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <!-- Fees -->
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <label
                                                for="input-cost-detail-fees"
                                                class="cost-descriptor"
                                            >
                                                <h3>Fees</h3>
                                            </label>
                                        </v-col>
                                        <v-col cols="12" sm="8">
                                            <InputCurrency
                                                id="input-cost-detail-fees"
                                                v-model.number="fees"
                                                class="white"
                                                :disabled="isPurchaseOrderStatusComplete"
                                            />
                                        </v-col>
                                    </v-row>
                                    <!-- Shipping -->
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <label
                                                for="input-cost-detail-shipping"
                                                class="cost-descriptor"
                                            >
                                                <h3>Shipping</h3>
                                            </label>
                                        </v-col>
                                        <v-col cols="12" sm="8">
                                            <InputCurrency
                                                id="input-cost-detail-shipping"
                                                v-model.number="shippingCost"
                                                class="white"
                                                :disabled="isPurchaseOrderStatusComplete"
                                            />
                                        </v-col>
                                    </v-row>
                                    <!-- Total -->
                                    <v-row>
                                        <v-col cols="6" sm="4">
                                            <h3>Total</h3>
                                        </v-col>
                                        <v-col cols="6" sm="8" class="text-right">
                                            <span class="body-1">
                                                {{ currentPurchaseOrderTotal }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
        <!-- Purchase Order Items Panel -->
        <v-expansion-panels
            v-model="panels"
            multiple
        >
            <!-- Purchase Order Items Panel -->
            <PanelPurchaseOrderLineItems
                :disabled="isPurchaseOrderStatusComplete"
            />

            <!-- Related Invoices Panel -->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <h2>Related Invoices</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <RelatedInvoices />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </main>
</template>

<script>

// Vuex
import { mapGetters, mapState } from 'vuex';

// Utils
import FormatUtil from '@/utils/FormatUtil';
import FormUtil from '@/utils/FormUtil';

// Components
import DropdownPrimarySupplier from '@/components/dropdowns/DropdownPrimarySupplier';
import InputCurrency from '@/components/base/inputs/InputCurrency';
import InputDatePicker from '@/components/InputDatePicker';
import ModalCreditCardInfo from './purchaseOrderDetail/ModalCreditCardInfo';
import PanelPurchaseOrderLineItems from './purchaseOrderDetail/PanelPurchaseOrderLineItems';
import RelatedInvoices from './purchaseOrderDetail/RelatedInvoices';

export default {
    name: 'PurchaseOrderDetail',
    components: {
        InputDatePicker,
        DropdownPrimarySupplier,
        ModalCreditCardInfo,
        InputCurrency,
        PanelPurchaseOrderLineItems,
        RelatedInvoices,
    },
    data() {
        return {
            FormatUtil,
            showCreditCardModal: false,
            panels: [ 0 ], // Auto open first panel
        };
    },
    computed: {
        ...mapGetters( {
            purchaseOrderHasItems: 'PurchaseOrder/hasItems',
            currentPurchaseOrderHasItems: 'PurchaseOrder/Detail/currentPurchaseOrderHasItems',
            currentPurchaseOrderSubtotal: 'PurchaseOrder/Detail/currentPurchaseOrderSubtotal',
            currentPurchaseOrderTotal: 'PurchaseOrder/Detail/currentPurchaseOrderTotal',
            isPurchaseOrderStatusComplete: 'PurchaseOrder/Detail/isPurchaseOrderStatusComplete',
        } ),
        ...mapState( {
            currentPurchaseOrder: ( state ) => state.PurchaseOrder.Detail.currentPurchaseOrder,
            primarySupplierList: ( state ) => state.GlobalResources.primarySupplierList,
        } ),
        /** @returns { String } */
        locationName() {
            return 'name' in this.currentPurchaseOrder.location
                ? FormatUtil.stripBookstoreFromLocationName( this.currentPurchaseOrder.location.name )
                : '';
        },
        /** @returns { Number } */
        numberOfUnitsOrdered() {
            return this.currentPurchaseOrderHasItems
                ? this.currentPurchaseOrder.purchaseOrderItemDTOList
                    .map( ( item ) => (
                        Number.isNaN( +item.requestedQuantity )
                            ? 0
                            : +item.requestedQuantity ) )
                    .reduce( ( accumulator, currentValue ) => accumulator + currentValue )
                : 0;
        },
        /** @returns { Number } */
        numberOfISBNsOrdered() {
            return this.currentPurchaseOrderHasItems
                ? new Set( this.currentPurchaseOrder.purchaseOrderItemDTOList
                    .map( ( item ) => item.productCode ) ).size
                : 0;
        },
        /** @returns { String } */
        supplierNote() {
            return ( this.currentPurchaseOrder.supplier && this.currentPurchaseOrder.supplier.note )
                ? this.currentPurchaseOrder.supplier.note
                : '';
        },
        /** @returns { String } */
        creditCardNotes() {
            return this.currentPurchaseOrder.purchaseOrderCreditCardDTO.note;
        },
        /** @returns { String } */
        creditCardLastFour() {
            return this.currentPurchaseOrder.purchaseOrderCreditCardDTO.lastFour;
        },

        confirmationId: {
            get() {
                return this.currentPurchaseOrder.confirmationId;
            },
            set( confirmationId ) {
                this.$store.commit( 'PurchaseOrder/Detail/SET_CURRENT_PURCHASE_ORDER_CONFIRMATION_ID', { confirmationId } );
            },
        },
        description: {
            get() {
                return this.currentPurchaseOrder.description;
            },
            set( description ) {
                this.$store.commit( 'PurchaseOrder/Detail/SET_CURRENT_PURCHASE_ORDER_DESCRIPTION', { description } );
            },
        },
        expectedReceiveDate: {
            get() {
                return this.currentPurchaseOrder.expectedReceiveDate;
            },
            set( expectedReceiveDate ) {
                this.$store.commit( 'PurchaseOrder/Detail/SET_CURRENT_PURCHASE_ORDER_EXPECTED_RECEIVE_DATE', { expectedReceiveDate } );
            },
        },
        fees: {
            get() {
                return this.currentPurchaseOrder.fees;
            },
            set( fees ) {
                if ( FormUtil.isValidCurrencyAmount( fees ) ) {
                    this.$store.commit( 'PurchaseOrder/Detail/SET_CURRENT_PURCHASE_ORDER_FEES', { fees } );
                }
            },
        },
        shippingCost: {
            get() {
                return this.currentPurchaseOrder.shippingCost;
            },
            set( shippingCost ) {
                if ( FormUtil.isValidCurrencyAmount( shippingCost ) ) {
                    this.$store.commit( 'PurchaseOrder/Detail/SET_CURRENT_PURCHASE_ORDER_SHIPPING_COST', { shippingCost } );
                }
            },
        },
        supplier: {
            get() {
                return this.currentPurchaseOrder.supplier;
            },
            set( supplier ) {
                this.$store.commit( 'PurchaseOrder/Detail/SET_CURRENT_PURCHASE_ORDER_SUPPLIER', { supplier } );
            },
        },
        trackingNumbers: {
            get() {
                return this.currentPurchaseOrder.trackingNumbers;
            },
            set( trackingNumbers ) {
                this.$store.commit( 'PurchaseOrder/Detail/SET_CURRENT_PURCHASE_ORDER_TRACKING_NUMBERS', { trackingNumbers } );
            },
        },
        transmitDate: {
            get() {
                return this.currentPurchaseOrder.transmitDate;
            },
            set( transmitDate ) {
                this.$store.commit( 'PurchaseOrder/Detail/SET_CURRENT_PURCHASE_ORDER_TRANSMIT_DATE', { transmitDate } );
            },
        },
        useCreditCard: {
            get() {
                return this.currentPurchaseOrder.useCreditCard;
            },
            set( useCreditCard ) {
                this.$store.commit( 'PurchaseOrder/Detail/SET_CURRENT_PURCHASE_ORDER_USE_CREDIT_CARD', { useCreditCard } );
                if ( useCreditCard ) {
                    this.displayCreditCardModal();
                }
            },
        },
    },
    methods: {
        displayCreditCardModal() {
            this.showCreditCardModal = true;
            this.$nextTick( () => {
                this.$refs.creditCardModal.$refs.closeModalButton.$el.focus();
            } );
        },
        hideCreditCardModal() {
            this.showCreditCardModal = false;
        },
    },
};
</script>

<style scoped lang="scss">
main {
    padding-bottom: 150px;
}
.po-detail-section {
    padding: 15px;
    margin-bottom: 1rem;

    h3 {
        font-size: 1rem;
    }

    .col-padding-dense {
        padding-left: 36px;
        padding-right: 36px;
    }

    .row-po-details {
        .row-supplier {
            margin-top: 1.25rem;
        }
        #po-supplier-notes {
            margin-top: 1.25rem;
            height: 235px;
            overflow: auto;
            background-color: #f2f2f2;
            border-radius: 4px;
            padding: 1rem;
        }
        .row-cost-card {
            margin-top: 4rem;

            &__card {
                border: none;
                background-color: #f2f2f2;
                height: 235px;
            }
        }
        ::v-deep .po-tracking-numbers textarea {
            line-height: 1.25;
            height: 3.5rem;
        }
    }
}
.v-expansion-panel {
    margin-bottom: 1rem;
}
</style>
